@import "variables";

/******************************************************************************
						Home Page Slider
*******************************************************************************/
#homepage-slider {
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 14px;
	max-width: 100%;
	max-height: 100%;
	overflow: hidden;
	position: relative;
	z-index: 1;
	float: left;
	background: #f6f6f6;
}

@media(max-width:991px){
  #homepage-slider {
      margin-bottom: 0;
  }
}
  /*****************************************************************************
  						BXSlider Styles
  ********************************************************************************/ 
  @media (max-width: 767px) {
    #homepage-slider {
      width: 100%;
      max-width: 100%;
	}
  }
  #homepage-slider #homeslider {
    margin: 0 auto;
    padding: 0; }
  #homepage-slider .homeslider-container {
    position: relative; }
  #homepage-slider .homeslider-image {
    position: absolute;
    left: 0;
    top: 0; }
  #homepage-slider .homeslider-description {
    position: absolute;
    left: 0;
    top: 0;
    padding: 50px 50px;
    cursor: pointer; }
    #homepage-slider .homeslider-description h2 {
      font-size: 2.7em;
      color: white;
      margin: 0;
      padding: 0; }
    #homepage-slider .homeslider-description p {
      width: 50%;
      font-size: 1.15em;
      line-height: 1.3em;
      padding: 0.5em 0;
      color: white; }
    #homepage-slider .homeslider-description button {
      font-size: 1.2em;
      text-transform: uppercase; }
    @media (max-width: 1200px) {
      #homepage-slider .homeslider-description {
        padding: 20px;
        font-size: 0.8em; } }
    @media (max-width: 768px) {
      #homepage-slider .homeslider-description {
        padding: 30px;
        font-size: 0.7em; }
        #homepage-slider .homeslider-description p {
          font-size: 1.5em; } }
    @media (max-width: 480px) {
      #homepage-slider .homeslider-description {
        padding: 30px;
        font-size: 0.5em; }
        #homepage-slider .homeslider-description p {
          font-size: 1.7em; } }
	#homepage-slider .bx-wrapper{
		margin: 0 auto !important;
	}
  #homepage-slider .bx-wrapper a {
	display: block;
	text-align: center;
  }
  #homepage-slider .bx-wrapper img {
    height: auto;
	display: inline-block !important;
  }
  #homepage-slider .bx-wrapper .bx-viewport {
    width: 100%;
    direction: ltr !important; }
  #homepage-slider .bx-wrapper .bx-pager.bx-default-pager a {
    background: lighten(theme-color(black),30%); }
  #homepage-slider .bx-wrapper .bx-controls-direction a {
    top: auto;
    margin-top: 0px;
    font-size: 1em;
    bottom: 10px;
    outline: 0;
    width: 52px;
    height: 52px;
    text-indent: -9999px;
    z-index: 9999;
    border: 2px solid theme-color(white);
    line-height: 18px; }
    #homepage-slider .bx-wrapper .bx-controls-direction a:before {
      height: 48px;
      width: 48px;
      display: block;
      background: theme-color(white);
      content: ".";
      opacity: 0.14;
      text-indent: -9999px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1; }
    #homepage-slider .bx-wrapper .bx-controls-direction a:after {
      display: block;
      width: 48px;
      height: 48px;
      color: theme-color(white);
      text-indent: 0;
      text-align: center;
      font-size: 21px;
      font-family: "FontAwesome";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      padding: 16px 0 0 0; }
    #homepage-slider .bx-wrapper .bx-controls-direction a:hover:before {
      background: #333;
      opacity: 0.83; }
  #homepage-slider .bx-wrapper .bx-prev {
    right: 60px;
    left: auto; }
    #homepage-slider .bx-wrapper .bx-prev:after {
      content: "\f053"; }
  #homepage-slider .bx-wrapper .bx-next:after {
    content: "\f054"; }

.ie8 #homepage-slider .bx-wrapper .bx-controls-direction a:before {
  background: #333; }
#slide-nav{
	margin-bottom: 0;
	margin-top: -40px;
	position: relative;
	z-index: 100;
	background: theme-color(white);
	border: #dce0e0 solid 1px;
	border-right:none; 
	clear: both;
	display: table;
	width: 100%;
	-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
	-o-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
}
.slide-nav-item{
	display: inline-block;
	/*float: left;*/
	display: table-cell;
	padding:15px;
	border-right:#ddd solid 1px; 
	cursor: pointer;
	color: lighten(theme-color(black),30%);
	position: relative;
}

@media(min-width:991px){
	.slide-nav-item{
		padding-right: 45px;
	}
	.slide-nav-item:after{
		content: "\f105";
		font-family: "FontAwesome";
		position: absolute;
		top: 50%;
		right: 15px;
		transform: translate(0,-50%);
		-webkit-transform: translate(0,-50%);
		width: 20px;
		height: 20px;
		border-radius:100%;
		border: lighten(theme-color(black),30%) solid 1px;
		line-height: 17px;
		text-align: center;
		color: lighten(theme-color(black),30%);
	}
	.slide-nav-item.active:after{
		color: theme-color(white);;
		border-color:theme-color(white);; 
	}
}
.slide-nav-item p{
	margin-bottom: 0;
}
.slide-nav-item .filsonMedium{
	font-size: 14px;
  color: lighten(theme-color(black),30%);
  line-height: 1.1;
}
.slide-nav-item.active{
	background: theme-color(primary);
	color: theme-color(white);
}
.slide-nav-item.active .filsonMedium{
	color: theme-color(white);
}